@media print {
  body {
    margin: 0;
    color: #000;
    background-color: #fff;
  }
  section,
  div,
  article {
    display: block;
  }

  @page {
    margin: 0 auto;
  }

  #homeprotocol {
    margin-top: 40px !important;
  }
}
