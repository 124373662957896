@font-face {
  font-family: "hn-bold";
  src: local("hn-bold"), url(./fonts/Graphik-Bold.woff2) format("woff2");
  font-weight: 700;
}
@font-face {
  font-family: "hn-semibold";
  src: local("hn-semibold"), url(./fonts/Graphik-Semibold.woff2) format("woff");
  font-weight: 600;
}
@font-face {
  font-family: "hn-medium";
  src: local("hn-medium"), url(./fonts/Graphik-Regular.woff2) format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "hn-light";
  src: local("hn-light"), url(./fonts/Graphik-Light.woff2) format("woff");
  font-weight: 100;
}

* {
  box-sizing: border-box;
  font-family: hn-medium, Arial;
}

body {
  margin: 0;
  font-family: hn-medium, Arial, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@media print {
  body {
    position: absolute !important;
    overflow: scroll !important;
  }
}
